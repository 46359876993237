import React from "react";
import ProductTemplate from "./product";
// import { FiArrowRightCircle } from "react-icons/fi";
// import { Link } from "gatsby";
// import Image from "@components/common/CloudinaryImage";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
// import { InlineWidget } from "react-calendly";
// import { FaParking } from "react-icons/fa";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            {/** Qué es¿? */}
            <div className="container lg:px-0 py-20">
                <Title>
                    Curs Presencial Específic Policia Municipal de Terrassa 2024 [10 hores - 21
                    d'abril]
                </Title>
                <Text>
                    Us presentem un curs específic per l'accés a la Policia Municipal de Terrassa
                    2024.
                    <br />
                    <br />
                    El diumenge 21 d'abril
                    <br />
                    <br />
                    De 9.00 a 14.00 i de 15.00 a 20.00 h
                    <br />
                    <br />
                    - Al curs ens centrarem en els conceptes clau de cara a l'examen amb tips i
                    explicacions detallades
                    <br />
                    <br />
                    - S'entregarà un dossier esquematitzat a tots els alumnes.
                    <br />
                    <br />
                    - Accés a un campus online amb psicotècnics de personalitat i aptitudinals
                    <br />
                    <br />
                    - Simulacre final
                    <br />
                    <br />
                    El curs és impartit al c/ Martí Codolar núm 18, Hospitalet de Llobregat
                    <br />
                    <br />
                    No t'ho pots perdre!
                </Text>

                {/*<div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                    <iframe
                        title={item.title}
                        width="100%"
                        height="600px"
                        src="https://www.youtube.com/embed/-yYf--WIgYQ"
                        frameBorder={"0"}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                    />
                </div>*/}
            </div>
        </ProductTemplate>
    );
};
export default Product;
